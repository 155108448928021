<template>
  <div class="userDetails">
    <div class="header">
      <div>
        <a-button @click="goBack" type="link" style="margin-right: 50px"
          >返回列表</a-button
        >
        <span>{{ phoneNum }}</span>
      </div>
      <div>
        <a-button style="margin-right: 50px" @click="stopServe"
          >停止服务</a-button
        >
        <a-button @click="immediatelyEvacuate">立即疏散</a-button>
      </div>
    </div>
    <a-tabs :tabBarGutter="100" size="large" default-active-key="1">
      <a-tab-pane key="1" tab="基本信息">
        <basic-info :basic-info="basicInfo" />
      </a-tab-pane>
    </a-tabs>
    <user-details-pop :title="title" :visible="visible" :phone-num="phoneNum" />
  </div>
</template>

<script>
import basicInfo from "./components/basicInfo.vue";
import userDetailsPop from "./components/userDetailsPop.vue";
import { getUserNodeDetails } from "../../server/api";
export default {
  name: "UserDetails",
  components: {
    basicInfo,
    userDetailsPop,
  },
  data() {
    return {
      phoneNum: "",
      paragraph: "",
      basicInfo: {},
      title: "",
      visible: false,
    };
  },
  created() {
    getUserNodeDetails(this.$route.query.id).then((res) => {
      const { user_info } = res;
      this.basicInfo = user_info;
      this.phoneNum = user_info.phone;
      console.log(this.phoneNum, "this.phoneNum");
    });
  },
  methods: {
    //返回列表页
    goBack() {
      this.$router.go(-1);
    },
    //停止服务
    stopServe() {
      this.visible = true;
      this.title = "停止服务";
    },
    //立即疏散
    immediatelyEvacuate() {
      this.visible = true;
      this.title = "选择疏散目标节点";
    },
    //弹窗取消按钮
    handleCancel() {
      this.visible = false;
    },
  },
};
</script>
<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}
</style>
