<template>
  <div class="userDetails">
    <a-modal
      v-model="visible"
      :closable="false"
      :maskClosable="false"
      :title="title"
    >
      <template slot="footer">
        <a-button key="back" @click="cancelChange"> 取消 </a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleOk"
        >
          确定
        </a-button>
      </template>
      <a-radio-group
        name="radioGroup"
        v-model="evacuateNode"
        :default-value="1"
        v-if="title === '选择疏散目标节点'"
      >
        <a-radio :value="1"> 疏散到任意节点 </a-radio>
        <a-radio :value="2"> 疏散到指定节点 </a-radio>
        <a-select
          v-show="evacuateNode === 2"
          style="width: 120px"
          placeholder="Select a person"
          v-model="assignNode"
        >
          <a-select-option
            v-for="item in nodeListInfo"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-radio-group>
      <div v-else>是否确定立即停止服务</div>
    </a-modal>
  </div>
</template>

<script>
import {
  getNodeList,
  evacuateUserNode,
  stopUserServe,
} from "../../../server/api";
export default {
  name: "UserDetailsPop",
  props: ["title", "visible", "phoneNum"],
  data() {
    return {
      loading: false,
      evacuateNode: 1,
      assignNode: "",
      nodeListInfo: [],
    };
  },
  created() {
    this.getNodeListFunc();
    console.log(this.phoneNum, "子元素");
  },
  methods: {
    //获取所有节点列表
    getNodeListFunc() {
      getNodeList({}).then((res) => {
        this.nodeListInfo = res.nodes;
      });
    },
    //弹窗确定按钮
    handleOk() {
      this.loading = true;
      if (this.title === "停止服务") {
        stopUserServe({ user_id: this.phoneNum }).then(() => {
          this.$message.info("该服务已暂停!");
          this.loading = false;
          this.cancelChange();
        });
      } else {
        if (this.evacuateNode === 1) {
          let dataObj = {
            user_id: this.phoneNum,
            destination_node: "",
          };
          this.evacuateUserNodeFunc(dataObj);
        } else {
          //疏散到指定节点
          if (this.assignNode === "") {
            this.$message.error("疏散到指定节点请选择节点！");
            this.loading = false;
            return;
          } else {
            let dataObj = {
              user_id: this.phoneNum,
              destination_node: this.assignNode,
            };
            console.log(dataObj);
            this.evacuateUserNodeFunc(dataObj);
          }
        }
      }
    },
    // 疏散节点
    evacuateUserNodeFunc(data) {
      evacuateUserNode(data)
        .then((res) => {
          console.log(res);
          this.$message.success("已疏散节点！");
          this.loading = false;
          this.cancelChange();
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.$message.error("疏散失败，请联系管理员！");
        });
    },
    //调用父组件的方法改变visible值
    cancelChange() {
      this.$parent.handleCancel();
    },
  },
};
</script>
<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}
</style>
