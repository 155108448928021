<template>
  <div class="basicInfo">
    <a-form
      :form="basicInfo"
      :label-col="{ span: 1 }"
      :wrapper-col="{ span: 12 }"
    >
      <!-- <a-form-item label="用户ID">
        <span>{{ basicInfo.id }}</span>
      </a-form-item> -->
      <a-form-item label="手机号">
        <span>{{ basicInfo.phone }}</span>
      </a-form-item>
      <a-form-item label="当前套餐">
        <span>{{ basicInfo.type }}</span>
      </a-form-item>
      <a-form-item label="套餐到期">
        <span>{{ basicInfo.time }}</span>
      </a-form-item>
      <a-form-item label="当前线路">
        <span>{{ basicInfo.region }}</span>
      </a-form-item>
      <a-form-item label="当前节点">
        <span>{{ basicInfo.node_ip }}</span>
      </a-form-item>
      <a-form-item label="当前端口">
        <span>{{ basicInfo.port }}</span>
      </a-form-item>
      <a-form-item label="本月流量">
        <span>{{ basicInfo.traffic_usage }}G</span>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
export default {
  name: "BasicInfo",
  props: ["basicInfo"],
};
</script>
<style scoped></style>
